import {sygnet} from './sygnet';
import {logo} from './logo';
import {logoNegative} from './logo-negative';

import {
    cibBehance,
    cibCcAmazonPay,
    cibCcAmex,
    cibCcApplePay,
    cibCcMastercard,
    cibCcVisa,
    cibDribbble,
    cibFacebook,
    cibFlickr,
    cibGithub,
    cibGooglePay,
    cibInstagram,
    cibLinkedin,
    cibPaypal,
    cibPinterest,
    cibReddit,
    cibSkype,
    cibStackoverflow,
    cibStripe,
    cibTumblr,
    cibTwitter,
    cibVimeo,
    cibVk,
    cibXing,
    cibYahoo,
    cibYoutube,
    cifAt,
    cifBr,
    cifCh,
    cifDe,
    cifDk,
    cifEs,
    cifFr,
    cifGb,
    cifIn,
    cifMt,
    cifNl,
    cifPl,
    cifRu,
    cifUs,
    cilAccountLogout,
    cilAddressBook,
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilArrowRight,
    cilArrowTop,
    cilAsterisk,
    cilBan,
    cilBasket,
    cilBath,
    cilBell,
    cilBold,
    cilBolt,
    cilBoltCircle,
    cilBookmark,
    cilCalculator,
    cilCalendar,
    cilChartPie,
    cilChatBubble,
    cilCheck,
    cilCheckCircle,
    cilChevronBottom,
    cilChevronDoubleDown,
    cilChevronDoubleUp,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCircle,
    cilClock,
    cilCloudDownload,
    cilCloudUpload,
    cilCode,
    cilCommentBubble,
    cilCommentSquare,
    cilCreditCard,
    cilCursor,
    cilCursorMove,
    cilDollar,
    cilDiamond,
    cilDrop,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilFace,
    cilFaceDead,
    cilFile,
    cilFullscreen,
    cilFullscreenExit,
    cilInfo,
    cilGlobeAlt,
    cilGraph,
    cilGrid,
    cilHistory,
    cilHome,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilInputPower,
    cilItalic,
    cilJustifyCenter,
    cilJustifyLeft,
    cilLanguage,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilListRich,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMediaPause,
    cilMap,
    cilMonitor,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperclip,
    cilPaperPlane,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPlus,
    cilPrint,
    cilPuzzle,
    cilReload,
    cilSave,
    cilScrubber,
    cilSend,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSmile,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilTrash,
    cilTv,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilViewModule,
    cilViewStream,
    cilWarning,
    cilWeightlifitng,
    cilX,
    cilXCircle,
} from '@coreui/icons';

export const icons = Object.assign(
    {},
    {
        sygnet,
        logo,
        logoNegative,
    },
    {
        cilAddressBook,
        cilAccountLogout,
        cilAlignCenter,
        cilAlignLeft,
        cilAlignRight,
        cilApplicationsSettings,
        cilArrowRight,
        cilArrowTop,
        cilAsterisk,
        cilBan,
        cilBath,
        cilBasket,
        cilBell,
        cilBold,
        cilBolt,
        cilBoltCircle,
        cilBookmark,
        cilCalculator,
        cilCalendar,
        cilCloudDownload,
        cilCloudUpload,
        cilChatBubble,
        cilChartPie,
        cilCheck,
        cilChevronBottom,
        cilChevronLeft,
        cilChevronRight,
        cilChevronTop,
        cilChevronDoubleDown,
        cilChevronDoubleUp,
        cilCircle,
        cilCheckCircle,
        cilCode,
        cilCommentBubble,
        cilCommentSquare,
        cilCreditCard,
        cilCursor,
        cilCursorMove,
        cilDiamond,
        cilDrop,
        cilDollar,
        cilEnvelopeClosed,
        cilEnvelopeLetter,
        cilEnvelopeOpen,
        cilEuro,
        cilGlobeAlt,
        cilGrid,
        cilFace,
        cilFaceDead,
        cilFile,
        cilFullscreen,
        cilFullscreenExit,
        cilInfo,
        cilGraph,
        cilHome,
        cilHistory,
        cilInbox,
        cilIndentDecrease,
        cilIndentIncrease,
        cilInputPower,
        cilItalic,
        cilJustifyCenter,
        cilJustifyLeft,
        cilLanguage,
        cilLaptop,
        cilLayers,
        cilLightbulb,
        cilList,
        cilListNumbered,
        cilListRich,
        cilLocationPin,
        cilLockLocked,
        cilMagnifyingGlass,
        cilMap,
        cilMediaPlay,
        cilMediaPause,
        cilMoon,
        cilMonitor,
        cilNotes,
        cilOptions,
        cilPaperclip,
        cilPaperPlane,
        cilPencil,
        cilPeople,
        cilPlus,
        cilPhone,
        cilPrint,
        cilPuzzle,
        cilReload,
        cilSave,
        cilScrubber,
        cilSettings,
        cilSend,
        cilShare,
        cilShareAll,
        cilShareBoxed,
        cilShieldAlt,
        cilSmile,
        cilSpeech,
        cilSpeedometer,
        cilSpreadsheet,
        cilStar,
        cilSun,
        cilTags,
        cilTask,
        cilTrash,
        cilTv,
        cilUnderline,
        cilUser,
        cilUserFemale,
        cilUserFollow,
        cilUserUnfollow,
        cilX,
        cilXCircle,
        cilWarning,
        cilWeightlifitng,
        cilClock,
        cilViewModule,
        cilViewStream,
    },
    {
        cifUs,
        cifBr,
        cifIn,
        cifFr,
        cifEs,
        cifGb,
        cifNl,
        cifPl,
        cifDe,
        cifAt,
        cifCh,
        cifDk,
        cifMt,
        cifRu,
    },
    {
        cibSkype,
        cibFacebook,
        cibTwitter,
        cibLinkedin,
        cibFlickr,
        cibTumblr,
        cibXing,
        cibGithub,
        cibStackoverflow,
        cibYoutube,
        cibDribbble,
        cibInstagram,
        cibPinterest,
        cibVk,
        cibYahoo,
        cibBehance,
        cibReddit,
        cibVimeo,
        cibCcMastercard,
        cibCcVisa,
        cibStripe,
        cibPaypal,
        cibGooglePay,
        cibCcApplePay,
        cibCcAmazonPay,
        cibCcAmex,
    },
);
