import { createStore } from 'redux';
import { FindManyCommentFieldQuery, FindManyGiftQuery, Pool } from './generated/graphql';

export const initialState: MyState = {
    sidebarShow: 'responsive',
    asideShow: false,
    darkMode: false,
    activeLanguage: 'de',
    availableCommentFields: [],
    defaultComment: '',
    minCharMessage: 80,
    minCharAsa: 50,
    newMessage: false,
    gifts: [],
    compactMode: true,
    playMode: false,
    nextPool: null,
    poolSelectedSource: null,
    hasActiveQueue: false,
    isLoggedIn: false,
    projectName: null,
    queueId: null,
};

export type MyState = {
    sidebarShow: '' | true | false | 'responsive';
    asideShow: boolean;
    darkMode: boolean;
    activeLanguage: 'de' | string;
    availableCommentFields: FindManyCommentFieldQuery['findManyCommentField'];
    defaultComment: string;
    minCharMessage: number;
    minCharAsa: number;
    newMessage: boolean;
    gifts: FindManyGiftQuery['findManyGift'];
    compactMode: boolean;
    playMode: boolean;
    nextPool: Pool | null;
    poolSelectedSource: string | null;
    hasActiveQueue: boolean;
    isLoggedIn: boolean;
    projectName: string | null;
    queueId: string | null;
};

const changeState = (state = initialState, { type, ...rest }: { type: string }) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest };
        default:
            return state;
    }
};

const store = createStore(changeState);
export default store;
