import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import * as serviceWorker from './serviceWorker';
import { icons } from './assets/icons';
import { Provider } from 'react-redux';
import store from './store';
import SimpleReactLightbox from 'simple-react-lightbox';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
React.icons = icons;

ReactDOM.render(
    <Provider store={store}>
        <SimpleReactLightbox>
            <App />
        </SimpleReactLightbox>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
