import React, { Component } from 'react';
import { HashRouter, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import './scss/style.scss';
import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { withTranslation } from 'react-i18next';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { StaticContext } from 'react-router';
import { useSelector } from 'react-redux';
import { MyState } from './store';

if (!process.env.REACT_APP_ENDPOINT || !process.env.REACT_APP_WS_ENDPOINT)
    throw new Error('env not loaded or endpoint missing');

const graphqlLink = createHttpLink({
    uri: process.env.REACT_APP_ENDPOINT,
    credentials: 'same-origin',
});

const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WS_ENDPOINT,
    options: {
        reconnect: true,
        connectionParams: () => {
            return { Authorization: localStorage.getItem('token') || null };
        },
    },
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `${token}` : '',
        },
    };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
        );

    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    authLink.concat(graphqlLink),
);

const clientApollo = new ApolloClient({
    //link: authLink.concat(graphqlLink),
    link: from([errorLink, splitLink]),
    cache: new InMemoryCache(),
});

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse" />
    </div>
);
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const PrivateRoute = (props: { path: string; name: string; render: any }) => {
    const token = localStorage.getItem('token');
    const isLoggedIn = useSelector((state: MyState) => state.isLoggedIn);
    const isAuthed = !!token && isLoggedIn;
    return (
        <Route
            {...props}
            render={(props) =>
                isAuthed ? (
                    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                    <TheLayout {...(props as any)} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            // eslint-disable-next-line react/prop-types
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

export const logout = async (): Promise<void> => {
    localStorage.clear();
    await clientApollo.resetStore();
};

type LocationState = {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    from: any;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = RouteComponentProps<{}, StaticContext, LocationState> & {
    component: React.FunctionComponent;
};

class App extends Component {
    static logout = logout;

    render() {
        return (
            <ApolloProvider client={clientApollo}>
                <HashRouter>
                    <React.Suspense fallback={loading}>
                        <Switch>
                            <Route exact path="/login" render={(props) => <Login {...(props as Props)} />} />
                            <Route exact path="/404" render={() => <Page404 />} />
                            <Route exact path="/500" render={() => <Page500 />} />
                            <PrivateRoute
                                path="/"
                                name="Home"
                                render={(props: JSX.IntrinsicAttributes) => <TheLayout {...props} />}
                            />
                        </Switch>
                    </React.Suspense>
                </HashRouter>
            </ApolloProvider>
        );
    }
}

export default withTranslation()(App);
